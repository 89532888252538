import {addLoader} from './components/loading-dots'

const cssClass = {
    show: 'show'
};

/**
 * Closes modal
 * @param {Element} backdrop 
 */
const closeModal = (backdrop) => {
    backdrop.classList.remove(cssClass.show);
    backdrop.querySelector('.modal-wrapper.show').classList.remove(cssClass.show);
    setTimeout(() => {
        backdrop.remove();
        document.body.removeAttribute('style');
    }, 250);
}

/**
 * Shows modal
 * @param {Element} backdrop 
 * @param {Element} wrapper 
 */
const fadeIn = (backdrop, wrapper) => {
    requestAnimationFrame(() =>
        setTimeout(() => {
            document.body.style.cssText = `
                overflow: hidden;
                padding-right: ${window.innerWidth - document.documentElement.clientWidth}px;
            `;
            backdrop.classList.add(cssClass.show);
        })
    );
    requestAnimationFrame(() =>
        setTimeout(() => {
            wrapper.classList.add(cssClass.show);
        })
    );
}

/**
 * Appends wrapper of modal to the backdrop
 * @param {Element} backdrop 
 * @returns {Element} wrapper
 */
const appendContentWrapper = (backdrop) => {
    const wrapper = document.createElement('div');
    wrapper.classList.add('modal-wrapper');
    backdrop.append(wrapper);
    return wrapper;
}

/**
 * Appends modal backdrop to body
 * @returns {Element} backdrop
 */
const addBackdrop = () => {
    const backdrop = document.createElement('div');
    backdrop.classList.add('modal-backdrop');
    document.body.append(backdrop);
    return backdrop;
}

/**
 * Shows modal content
 * @param {String} eventId 
 * @param {String} source 
 * @param {Function} callback 
 */
const openModal = (eventId, source, callback) => {
    eventId = (eventId) ? parseInt(eventId) : 0;
    
    // Returns if there is no content for modal
    if (!eventId) return;

    const backdrop = addBackdrop();
    const wrapper = appendContentWrapper(backdrop);
    // add loading animation
    addLoader(wrapper, {inner: true});
    fadeIn(backdrop, wrapper);

    const url = new URL(window.location.origin + source);
    url.searchParams.append('id', eventId);

    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = "json";
    xhr.onload = () => {
        if (xhr.status === 200) {
            callback(xhr.response, wrapper);
        }
    };
    xhr.send();
}

/**
 * Handles click on modal toggler
 * @param {Event} e 
 */
const handleClickOnToggler = (e) => {
    if (e.target.matches('[data-toggle="modal"]')) {
        e.preventDefault();    
        const toggler = e.target;
        const eventId = toggler.getAttribute('data-event-id');
        const source = toggler.getAttribute('data-source');
        if (eventId && source) {
            openModal(eventId, source, (data, wrapper) => {
                wrapper.innerHTML = data.content;
            });
        }
    }
}

/**
 * Handles click on backdrop
 * @param {Event} e 
 * @returns 
 */
const handleClickOnBackdrop = (e) => {
    if (!e.target.matches('.modal-backdrop.show')) return;
    closeModal(e.target);
};

/**
 * Handles click on close button
 * @param {Event} e 
 * @returns 
 */
const handleClickOnCloseButton = (e) => {
    if (e.target.offsetParent) {
        if (!e.target.matches('.modal-close') && !e.target.offsetParent.matches('.modal-close')) return;
        const backdrop = document.querySelector('.modal-backdrop.show')
        if (backdrop) closeModal(backdrop);
    }
};

document.addEventListener('click', (e) => {
    handleClickOnToggler(e);
    handleClickOnBackdrop(e);
    handleClickOnCloseButton(e);
});
window.addEventListener('keyup', (e) => {
    // esc key pressed?
    if (e.code === 'Escape') {
        // get shown modal
        const backdrop = document.querySelector('.modal-backdrop.show')
        if (backdrop) closeModal(backdrop);
    }
});
